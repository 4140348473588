import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, IconButton} from '@mui/material';
import { Home, Info, Favorite, ChildFriendly, Email, ExpandLess, ExpandMore, Menu } from '@mui/icons-material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const getSubpath = (path) => {
    if (path.startsWith('/huidige')) {
        return 6;
    } else if (path.startsWith('/verleden')) {
        return 7;
    } else {
        return 0;
    }
}

const initPath = () => {
    const path = window.location.pathname;
    switch (path) {
        case '/':
            return 0;
        case '/over-ons':
            return 1;
        case '/verzorging':
            return 2;
        case '/mannen':
            return 3;
        case '/vrouwen':
            return 4;
        case '/in-memoriam':
            return 5;
        case '/huidige':
            return 6;
        case '/verleden':
            return 7;
        case '/reserveren':
            return 8;
        case '/contact':
            return 9;
        default:
            return getSubpath(path);
    }
}

export default function Navigation() {
    const [selectedIndex, setSelectedIndex] = useState(initPath());
    const [infoIsOpen, setInfoIsOpen] = useState([1, 2].includes(selectedIndex));
    const [puppyIsOpen, setPuppyIsOpen] = useState([6, 7, 8].includes(selectedIndex));
    const [ourIsOpen, setOurIsOpen] = useState([3, 4, 5].includes(selectedIndex));
    const [navIsOpen, setNavIsOpen] = useState(false);
    const clickHandler = (event, index) => {
        setSelectedIndex(index);
    }
    const ourClick = () => {
        setOurIsOpen(!ourIsOpen);
        setPuppyIsOpen(false);
        setInfoIsOpen(false);
    }
    const puppyClick = () => {
        setOurIsOpen(false);
        setPuppyIsOpen(!puppyIsOpen);
        setInfoIsOpen(false);
    }
    const infoClick = () => {
        setOurIsOpen(false);
        setPuppyIsOpen(false);
        setInfoIsOpen(!infoIsOpen);
    }
    return (
    <div className="App">
        <IconButton onClick={() => setNavIsOpen(!navIsOpen)} className="nav-button" size='large'><Menu color='secondary' fontSize='inherit' /></IconButton>
        <Link to="/">
            <div className="name-container">
                Rattery Wiggles
            </div>
        </Link>
        <List component="nav" className={ `${navIsOpen && "nav-active"}` }>
            <Link to="/" onClick={() => setNavIsOpen(false)}>
                <ListItemButton 
                    selected={selectedIndex === 0}
                    onClick={(event) => clickHandler(event, 0)}>
                    <ListItemIcon className='nav-icon'><Home /></ListItemIcon> 
                    <ListItemText>Home</ListItemText>
                </ListItemButton>
            </Link>
            <ListItemButton onClick={infoClick}>
                <ListItemIcon className='nav-icon'><Info /></ListItemIcon>
                <ListItemText>Informatie</ListItemText> 
                { infoIsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={infoIsOpen} unmountOnExit>
                <Link to="/over-ons" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}} 
                    selected={selectedIndex === 1}
                    onClick={(event) => clickHandler(event, 1)}>
                        <ListItemText>Over Ons</ListItemText>
                    </ListItemButton>
                </Link>
                <Link to="/verzorging" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}} 
                    selected={selectedIndex === 2}
                    onClick={(event) => clickHandler(event, 2)}>
                        <ListItemText>Verzorging</ListItemText>
                    </ListItemButton>
                </Link>
            </Collapse>
            <ListItemButton onClick={ourClick}>
                <ListItemIcon className='nav-icon'><Favorite /></ListItemIcon> 
                <ListItemText>Onze Ratten</ListItemText>
                { ourIsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={ourIsOpen} unmountOnExit>
                <Link to="/mannen" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}}
                    selected={selectedIndex === 3}
                    onClick={(event) => clickHandler(event, 3)}>
                        <ListItemText>Mannen</ListItemText>
                    </ListItemButton>
                </Link>
                <Link to="/vrouwen" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}}
                    selected={selectedIndex === 4}
                    onClick={(event) => clickHandler(event, 4)}>
                        <ListItemText>Vrouwen</ListItemText>
                    </ListItemButton>
                </Link>
                <Link to="/in-memoriam" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}}
                    selected={selectedIndex === 5}
                    onClick={(event) => clickHandler(event, 5)}>
                        <ListItemText>In Memoriam</ListItemText>
                    </ListItemButton>
                </Link>
            </Collapse>
            <ListItemButton onClick={puppyClick}>
                <ListItemIcon className='nav-icon'><ChildFriendly /></ListItemIcon> 
                <ListItemText>Ratten Puppies</ListItemText>
                { puppyIsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={puppyIsOpen} unmountOnExit>
                <Link to="/huidige" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}}
                    selected={selectedIndex === 6}
                    onClick={(event) => clickHandler(event, 6)}>
                        <ListItemText>Huidige Nesten</ListItemText>
                    </ListItemButton>
                </Link>
                <Link to="/verleden" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}}
                    selected={selectedIndex === 7}
                    onClick={(event) => clickHandler(event, 7)}>
                        <ListItemText>Verleden Nesten</ListItemText>
                    </ListItemButton>
                </Link>
                <Link to="/reserveren" className='nested' onClick={() => setNavIsOpen(false)}>
                    <ListItemButton sx={{ pl: 4}}
                    selected={selectedIndex === 8}
                    onClick={(event) => clickHandler(event, 8)}>
                        <ListItemText>Reserveren</ListItemText>
                    </ListItemButton>
                </Link>
            </Collapse>
            <Link to="/contact" onClick={() => setNavIsOpen(false)}>
                <ListItemButton
                    selected={selectedIndex === 9}
                    onClick={(event) => clickHandler(event, 9)}>
                    <ListItemIcon className='nav-icon'><Email /></ListItemIcon>
                    <ListItemText>Contact</ListItemText>
                </ListItemButton>
            </Link>
        </List>
    </div>
    );
}