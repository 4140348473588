import React from 'react';
import bannerPhoto from './resrc/home/20230915_170458.jpg';
import bottomPhoto from './resrc/home/20220320_205113.jpg';

const Home = () => {
    return (
        <div className="container home-container" style={{ backgroundColor: 'transparent' }}>
            <div className="home-banner">
                <img src={bannerPhoto} className='home-photo' />
                <div className='home-banner-text'>Rattery Wiggles</div>
            </div>
            <div className="content">
                <h1 className='home-title'>Home</h1>
                <p>Welkom bij Rattery Wiggles. Wij zijn een hobbymatige, kleinschalige rattery in Groningen.</p>
            </div>
            <div className='home-banner bottom'>
                <img src={bottomPhoto} className='home-bottom-photo' />
            </div>
        </div>

)};

export default Home;