import React from 'react';
import { PropTypes } from 'prop-types';
// import upcomingNests from './resrc/nests/upcoming/folders.jsx';


const Upcoming = () => {
    return (
        <div className="container">
            <div className="content">
                <h1>Huidige nesten</h1>
                <p>Er zijn geen huidige nesten.</p>
            </div>
        </div>
    )
};

Upcoming.propTypes = {
    nests: PropTypes.object,
};

export default Upcoming;