import React, { useState, useRef } from 'react';
import propTypes from "prop-types";
import { TextField, Button, AppBar, Toolbar, IconButton, DialogContent, Alert, Switch } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import isEmail from 'validator/lib/isEmail';
import { styled } from '@mui/material/styles';

const API_PATH = 'https://ratterywiggles.nl/server.php';

const StyledField = styled(TextField)({
        [`& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline`]: {
          borderColor: "rgba(107, 23, 1)"
        },
        [`& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
            borderColor: "rgb(139, 5, 50)"
        },
});

function Contact ({ dialog, fullScreen, onClose }) {
    const [state, setState] = useState({
        fname: '',
        lname: '',
        email: '',
        message: '',
        mailList: false,
        mailSent: false,
        error: null,
    });
    const contactForm = useRef();
    const [fnameError, setFnameError] = useState('');
    const [lnameError, setLnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    function chooseType( content ) {
        if(dialog && !fullScreen){
            return (<DialogContent><h2>Contact</h2> { content }</DialogContent>);
        }
        else if (dialog) {
            return (
            <>
                <AppBar sx={{ position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <h1>Contact</h1>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ borderRadius: 0 }}>
                    { content }
                </DialogContent>
            </>
        );
        }
        else {
            return (
                <div className="container contact-container">
                    <h1>Contact</h1>
                    { content }
                </div>
            );
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        contactForm.current.reportValidity()
        if (state.fname === '' || state.lname === '' || state.email === '') {
            setState({ ...state, error: 'Vul alle verplichte velden in.' });
            return;
        }
        else if (contactForm.current.reportValidity() === true) {
            axios({
                method: 'post',
                url: `${API_PATH}`,
                headers: { 'content-type': 'application/json' },
                data: state
            })
                .then(result => {
                    setState({
                        ...state,
                        mailSent: result.data.sent
                    })
                })
                .catch(error => setState({ ...state, error: error.message }));
        }
    };
    return chooseType(
            <form className="contact-form" 
                action='https://ratterywiggles.nl/server.php' 
                onSubmit={(event) => handleSubmit(event)}
                ref={contactForm}
                >
                <div className="contact-form-row">
                    <label htmlFor="fname">Voornaam</label>
                    <StyledField 
                        type="text" 
                        id="fname" 
                        name="firstname" 
                        label="Voornaam" 
                        variant="outlined" 
                        className='contact-form-input'
                        color='secondary'
                        value={state.fname} 
                        onChange={e => setState({ ...state, fname: e.target.value })}
                        onBlur={e => e.target.value === '' ? setFnameError("Dit veld is verplicht.") : setFnameError('')}
                        required 
                        error={fnameError}
                        helperText={fnameError ? fnameError : null} />
                </div>
                <div className="contact-form-row">
                    <label htmlFor="lname">Achternaam</label>
                    <StyledField 
                        type="text" 
                        id="lname" 
                        name="lastname" 
                        label="Achternaam" 
                        variant="outlined" 
                        className='contact-form-input'
                        color='secondary'
                        value={state.lname} 
                        onChange={e => setState({ ...state, lname: e.target.value })}
                        onBlur={e => e.target.value === '' ? setLnameError("Dit veld is verplicht.") : setLnameError('')}
                        required 
                        error={lnameError}
                        helperText={lnameError ? lnameError : null} />
                </div>
                <div className="contact-form-row">
                    <label htmlFor="email">Email</label>
                    <StyledField 
                        type="email" 
                        id="email" 
                        name="email" 
                        label="Email" 
                        value={state.email}
                        onChange={e => setState({ ...state, email: e.target.value })}
                        onBlur={e => isEmail(e.target.value) ? setEmailError('') : setEmailError("Dit veld is verplicht.") }
                        variant="outlined"  
                        className='contact-form-input'
                        color='secondary'
                        required 
                        error={emailError}
                        helperText={emailError ? emailError : null} />
                </div>
                <div className="contact-form-row">
                    <label htmlFor="mailList">Inschrijven voor de nieuwsbrief</label>
                    <Switch id="mailList" value={state.mailList} onChange={e => setState({ ...state, mailList: e.target.checked })} color='primary' />
                </div>
                <div className="contact-form-row">
                    <label htmlFor="message">Bericht</label>
                    <StyledField 
                        multiline 
                        rows={4} 
                        id="message" 
                        name="message" 
                        label="Bericht"
                        color='secondary'
                        value={state.message}
                        onChange={e => setState({ ...state, message: e.target.value })} 
                        variant="outlined"  
                        className='contact-form-input textarea' />
                </div>
                <Button type='submit' variant='contained' className='submit-button' onClick={e => handleSubmit(e)}>
                    Verstuur
                </Button>
                <div>
                    {state.mailSent &&
                        <div className="contact-form-row">
                            <Alert severity="success">Bedankt voor je bericht! Ik zal zo snel mogelijk reageren.</Alert>
                        </div>
                    }
                    {state.error &&
                        <div className="contact-form-row">
                            <Alert severity="error">{ state.error }</Alert>
                        </div>
                    }
                </div>
            </form>
)};

Contact.propTypes = {
    dialog: propTypes.bool,
    fullScreen: propTypes.bool,
    handleClose: propTypes.func
}

export default Contact