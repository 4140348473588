import React from 'react';

const Info = () => {
    return (
        <div className="container">
            <div className="content">
                <h1>Over ons</h1>
                    <p>Hoi, mijn naam is Naomi Raspe en ik fok met de hulp van mijn vriend tamme ratjes.
                    </p>
                    <p>Na enige tijd zelf ratten als huisdieren gehad te hebben merkte ik dat het verschil qua temperament en gezondheid van de beschikbare ratten in Nederland erg uiteenloopt. Hiernaast is het soms best moeilijk om ratjes bij een goede rattery/fokker te krijgen. Er zijn nou eenmaal niet zo veel ratteries in Nederland, en degenen die er zijn hebben vaak lange wachtlijsten. Na zelf een aantal keer flink teleurgesteld te zijn geweest (vooral in de omstandigheden waarin de dieren gehouden werden) heb ik besloten om zelf ratjes te gaan kweken. Alle praktische zaken worden voornamelijk door mij gedaan, Naomi. Mijn vriend Iisakki helpt hier ook weleens aan mee, maar hij is voornamelijk verantwoordelijk voor de website.
                    </p>
                    <p>Ons doel is om zo lief en tam mogelijke ratjes te kweken, hierbij staat gezondheid op nummer één. Agouti based kleuren zijn mijn favoriet, en ik probeer dit dus zo veel mogelijk naar voren te laten komen in mijn nesten.
                    </p>
            </div>
        </div>
)};

export default Info