import React from 'react';
import { pastNests } from './resrc/nests/past/folders';
import { NestNav } from '../components/rat_pairing';

const Past = () => {
    return (
        <div className="container">
            <div className="content">
                <h1>Verleden nesten</h1>
                <NestNav nests={pastNests} />
            </div>
        </div>
)};

export default Past