import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { Card, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";


function ProfileCard ({ src, name, text, info }) {
    const [showInfo, setShowInfo] = useState(false);
    const infoDecoded = info.split('\n').map(item => item.split(","))
    const infoTable = []
    infoDecoded.forEach((element, i) => {
        infoTable.push(
            <tr key={i}>
                <td key={element[0]}>
                    <b>{ element[0] }</b>
                </td>
                <td key={element[1]}>
                    { element[1] }
                </td>
            </tr>
        )
    });
    return (
        <Card className="rat-profile">
            <div className="rat-profile-photo-container">
                <IconButton onClick={ () => setShowInfo(prevState => !prevState)} className="rat-info-button" size="large" color="primary" aria-label="info">
                    <Info fontSize="inherit"/>
                </IconButton>
               <img src={src} className="rat-profile-photo" />
               { showInfo && <div className="rat-profile-info">
                    <table>
                        <tbody>
                            { infoTable }
                        </tbody>
                    </table>
                    </div> }
            </div>
            <div className="rat-profile-contents">
                <h2>{name}</h2>
                <p>{text}</p>
            </div>
        </Card>
    )
}

ProfileCard.propTypes = {
    src: propTypes.string,
    name: propTypes.string,
    text: propTypes.string,
    info: propTypes.string
};

export default function RatProfile ({ images, title, path }) {
    const [profiles, setProfiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        async function getProfile() {
            for(const image of images){
                const list = image.split('/');
                const name = list[list.length - 1].split('.')[0];
                const textFile = require("../pages/resrc/" + path + "/" + name +".txt");
                const infoFile = require("../pages/resrc/" + path + "/" + name +"_info.txt");

                try {
                    const text = await fetch(textFile).then(x => x.text())
                    const info = await fetch(infoFile).then(x => x.text())
                    const profile = {"image": image, "name": name, "text": text, "info": info}
                    setProfiles(profiles => [...profiles, profile]);
                } catch (error) {
                    console.log(error)
                    setError(error)
                    setLoading(false)
                }
            }
            setLoading(false)
        };
        getProfile();
    }, []);
    
    if (loading) {
        return (    
            <div className="container rat-profile-container">
                <h1>{title}</h1>
                <div className='rat-profile-scroller'>
                    loading...
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="container rat-profile-container">
                <h1>{title}</h1>
                <div className='rat-profile-scroller'>
                    Error: {error.message}
                </div>
            </div>
        )
    }

    return (
        <div className="container rat-profile-container">
            <h1 className="rat-page-title">{title}</h1>
            <div className='rat-profile-row'>
                <div className='rat-profile-column'>
                    { profiles.map((item, index) => index % 2 === 0 ? (
                        <ProfileCard key={item["name"]} src={item["image"]} name={item["name"]} text={item["text"]} info={item["info"]} />
                    ) : null) }
                </div>
                <div className='rat-profile-column'>
                    { profiles.map((item, index) => index % 2 !== 0 ? (
                        <ProfileCard key={item["name"]} src={item["image"]} name={item["name"]} text={item["text"]} info={item["info"]} />
                    ) : null) }
                </div>
            </div>
        </div>
    )
}

RatProfile.propTypes = {
    images: propTypes.array,
    title: propTypes.string,
    path: propTypes.string
}