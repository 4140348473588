import React from 'react';

const Care = () => {
    return (
    <div className="container">
        <div className="content">
            <h1>Verzorging</h1>
                <p>Over het houden van huisdieren verschillen de meningen nogal, zo ook bij ratten. Er zijn veel manieren waarop je het ‘goed’, maar ook ‘fout’ kunt doen. Wij houden ons aan de onderstaande ‘basisregels’.
                </p>
            <h2>Hoeveelheid ratten</h2>
                <p>Je kunt ratten niet alleen houden. Ratten zijn enorm sociale dieren en eenzaamheid kan veel schade aanrichten. Je rat kan depressief worden (niet willen eten, veel slapen en niet uit zijn huisje willen komen) en in de ergste gevallen kan het ervoor zorgen dat de rat zichzelf gaat verwonden. Eenzaamheid in ratten moet absoluut niet onderschat worden.
                </p>
                <p>De beste optie is dus om 3 (of meer) ratten te houden. Dit betekent dat geen enkele rat alleen komt te zitten wanneer er een overlijdt. 
                </p>
                <p>Wil je stoppen met het houden van ratten? Zorg dan dat je 2 resterende ratten van je groep samen herplaatst. Een herplaatsing is voor ratten een stuk minder stressvol als ze het niet alleen hoeven te doen.
                </p>
            <h2>Kooi</h2>
                <p>De kooi waarin je ratten verblijven moet natuurlijk groot genoeg zijn. Je kunt hiervoor een handige calculator gebruiken zoals <a href="https://tammeratten.nl/calculator.html" target='_blank' rel='noreferrer'>deze</a>. Zorg dat de kooi op een goede plek staat. Vermijd tocht en direct zonlicht. 
                </p>
                <p>Het is belangrijk dat de kooi geen traliebodem heeft. Dit kan ‘bumblefoot’ veroorzaken. Dit is een aandoening waarbij ratten ontstoken en opgezwollen voetzolen krijgen. Veel grote rattenkooien hebben een traliebodem waar een vlakke plaat op ligt, dit is wel geschikt. Aangezien ratten erg van klimmen houden is het een goed idee om een kooi aan te schaffen waarbij in ieder geval 2 van de wanden horizontale tralies hebben. Let er wel op dat de tralieafstand niet te groot is, vooral jonge ratten kunnen hier soms door ontsnappen.
                </p>
                <p>Qua bodembedekking is er heel veel mogelijk, van fleece tot kartonsnippers. Het enige waar hierbij op gelet moet worden is dat de bodembedekking zo stofvrij mogelijk is en urine goed absorbeert (dus geen kranten). Ratten hebben namelijk kwetsbare longen die snel geïrriteerd kunnen raken door stof en urinedampen (gebruik hout minimaal). Wij gebruiken kartonsnippers in de bodembakken, back2nature in de toiletjes en fleece op de plateaus.
                </p>
                <p>Je kunt je ratten aanleren hun behoefte (voor het grootste gedeelte) op een speciale toiletbak te doen, maar dit is geen must. Er zijn verschillende manieren om je ratten zindelijk te maken, hier is veel informatie over te vinden op het internet.
                </p>
                <p>Ratten zijn erg slim en houden van spelen. Ze hebben dus veel verrijking in hun kooi nodig. Zolang je er voor zorgt dat deze verrijking veilig is voor de rat kun je het zo gek maken als je zelf wilt. Favoriet zijn dingen als hangmatjes, mandjes, huisjes, tunnels etc. Als je besluit een loopwiel voor je ratten aan te schaffen, zorg er dan voor dat deze geen tralies heeft, maar een vlakke bodem. Hiernaast is het belangrijk dat het loopwiel groot genoeg is zodat de ratten met een rechte rug kunnen rennen (30-40 cm). 
                </p>
            <h2>Voeding</h2>
                <p>Er zijn twee soorten basisvoeding voor ratten: gemengd voer en (lab)brokken.
                </p>
            <h3>Gemengd voer</h3>
                <p>Gemengd voer bestaat uit allerlei verschillende losse ingrediënten zoals granen, gedroogde legumes, meelwormen etc.
                </p>
                <p>Het voordeel van gemend voer is dat het genoeg variatie biedt. Het nadeel is dat het meeste gemengde voer uit de dierenwinkels niet echt geschikt is voor ratten. Vaak zitten hier te veel eiwitten in, wat vooral voor mannetjes ratten schadelijk kan zijn voor de nieren. Hiernaast kunnen de ratten bij gemengd voer de lekkere (en vaak ongezondere) dingen ertussen uit vissen en de rest van het voer laten liggen.
                </p>
                <p>Zijn jouw ratten geen kieskeurige eters en kies je toch voor gemengd voer? Zorg dan dat het eiwitpercentage van het voer niet hoger is dan 15% en het vetpercentage niet hoger dan 5% is.
                </p>
            <h3>Brokken</h3>
                <p>Brokken zijn net als katten- en hondenbrokjes kleine brokjes waarbij alle ingrediënten samen verwerkt zijn tot losse brokjes. Elk brokje is precies hetzelfde waardoor je zeker weet dat je ratten alles binnen krijgen dat ze nodig hebben. Ze kunnen niet alleen de lekkere dingen ertussen uit vissen. Het nadeel hieraan is dat er geen variatie in zit. 
                </p>
                <p>Over het algemeen wordt Science Selective Complete Rat & Mouse gezien als het beste rattenvoer dat verkrijgbaar is in Nederland. Wij hebben hier zelf ook erg goede ervaringen mee. Om ervoor te zorgen dat je ratten toch wat variatie in hun dieet krijgen is het verstandig om ze naast de brokken elke dag wat verse groeten/fruit/kruiden te geven. Pas wel op met dingen zoals komkommer die veel water bevatten, dit kan voor diarree zorgen. Als je niet zeker weet of je rat iets wel of niet mag hebben, is google je beste vriend.
                </p>
            <h3>Ons voer</h3>
                <p>Wij mengen net als veel andere ratteries ons eigen voer aan de hand van het boek ‘The Scuttling Gourmet’. Door zelf voer te mengen weten we zeker dat onze ratten alles krijgen wat ze nodig hebben. Vooral voor de rittens en zwangere/lacterende ratten is dit belangrijk, aangezien zij andere behoeften hebben. Zelf voer mengen is niet zo makkelijk als het klinkt, je kunt namelijk niet zomaar wat dingen bij elkaar gooien. Er zit veel lees- en rekenwerk achter.
                </p>
        </div>
    </div>
)};

export default Care