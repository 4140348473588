import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const Reserve = () => {
    return (
        <div className="container">
            <div className="content">
                <h1>Reserveren</h1>
                <p>Zodra er een nestje beschikbaar is zal hier een nieuwsbrief over worden uitgestuurd, we zullen het nestje ook op onze social media plaatsen. Je kunt je aanmelden voor onze nieuwsbrief via de &lsquo;contact&rsquo;-pagina op deze website.</p>
                <p>In de nieuwsbrief zal een link staan naar het reserveringsformulier voor het desbetreffende nestje. In dit formulier wordt gevraagd naar de hoeveelheid rittens/geslacht die je zou willen reserveren. Als er een reserveringsformulier online is, zul je deze ook onderaan deze pagina vinden.</p>
                <p>Nadat er genoeg reserveringen binnen zijn zal dit formulier gesloten worden.  Wij zullen reserveerders kiezen op basis van eerste reacties, langste wachttijd en wat qua verdeling van de hoeveelheid rittens/geslachten het beste past.</p>
                <p>De uitgekozen reserveerders krijgen de mogelijkheid om hun ritten voorkeur aan te geven en wij zullen hierna de rittens onder de reserveerders verdelen. We proberen zoveel mogelijk rekening te houden met iedereens voorkeur, maar helaas is het niet altijd mogelijk om hier (geheel) aan te voldoen. Hou hier rekening mee.</p>
                <p><b>Let op!</b> Het invullen van het reserveringsformulier is dus geen garantie op rittens!</p>
                <p>Na het toewijzen van de rittens kunnen de reserveerders de aanbetaling voldoen en is de reservering bevestigd. De rest van de kosten voor de rittens kunnen voldaan worden bij het ophalen of elk gewenst moment daarvoor.</p>
                <h2>Algemene voorwaarden</h2>
                <ol>
                    <li>De kosten bedragen €25,- per ritten voor huisdieren, en €30,- per ritten voor fokdieren, ongeacht geslacht, oorstand, kleur etc.</li>
                    <li>Voor het reserveren van een ritten wordt door de koper een aanbetaling gedaan van 50% van de vraagprijs voor de ritten(s). De koper ontvangt hiervoor een betaalverzoek om de reservering te bevestigen, als deze niet binnen 7 dagen voldaan is, vervalt de reservering.</li>
                    <li>Als de koper de reservering annuleert, krijgt de koper het bedrag van de aanbetaling NIET terug.</li>
                    <li>De Rattery mag op elk moment ervoor kiezen een reservering te annuleren, om wat voor reden dan ook. In dat geval krijgt de koper het bedrag van de aanbetaling, of eventueel het gehele bedrag voor de ritten(s), WEL terug.</li>
                    <li>De koper mag niet met de rittens fokken tenzij dit van tevoren is afgesproken.</li>
                    <li>Ik en de Rattery kunnen op geen enkele manier aansprakelijk gesteld worden voor ziekte of overlijden van de ratten na verhuizing, tenzij er sprake is van aantoonbare nalatigheid. Wij vergoeden geen dierenartskosten.</li>
                    <li>In het geval van ziekte, vroegtijdig overlijden of herplaatsing van de rittens, word ik hier graag van op de hoogte gebracht en in contact gebracht met de nieuwe eigenaars.</li>
                    <li>Je mag alleen rittens reserveren als je 18 jaar of ouder bent óf een schriftelijke verklaring van je ouders/verzorgers hebt. Hier kan eventueel een uitzondering op gemaakt worden.</li>
                </ol>
            {/* <Button variant="contained" href="https://docs.google.com/forms/d/e/1FAIpQLSeIQU1Bc2R1AXxZflXRqApqbQHdaK3OqJu3DVj7rOMUxSU1WA/viewform" target='_blank' className='reserve-button'>Reserveren</Button> */}
            </div>
        </div>  
    )};

export default Reserve