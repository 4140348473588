import React from 'react';
import { Outlet } from "react-router-dom";
import Navigation from './navigation';
import Contact from '../pages/contact';
import { Fab, useMediaQuery, Dialog } from '@mui/material';
import Email from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import ConsentBanner from './consent.jsx';

const Layout = () => {
    const [open, setOpen] = React.useState(false);
    const fullScreen = useMediaQuery('(max-width:800px)');

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
            <ConsentBanner />
            <Navigation />
            <Outlet />
            <Fab color='secondary' aria-label='email'  className='contact-button' onClick={handleOpen}>
                <Email />
            </Fab>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="contact-form"
                fullWidth
                maxWidth={'md'}>
                <Contact dialog={true} fullScreen={fullScreen} onClose={handleClose}/>
            </Dialog>
            {
            // links are for SSG 
            }
            <Link to='/over-ons' />
            <Link to='/verzorging' />
            <Link to='/mannen' />
            <Link to='/vrouwen' />
            <Link to='/in-memoriam' />
            <Link to='/contact' />
            <Link to='/huidige' />
            <Link to='/huidige/L2' />
            <Link to='/huidige/L3' />
            <Link to='/verleden' />
            <Link to='/reserveren' />
        </>
    )
};

export default Layout;