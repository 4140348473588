async function importAll(r) {
  return r.keys().map(r);
  };

const nest1 = importAll(require.context('./L2/', false, /\.(png|jpe?g|svg)$/));
nest1.posts = importAll(require.context('./L2/posts/', true, /\.(png|jpe?g|svg)$/));
nest1.text = "Witje en Loki zijn in de nacht van 1-4-2024 tot 2-4-2024 op date geweest. In de nacht van 24-5-2024 is Witje bevallen van 14 rittens. Na een paar dagen staakte haar melkproductie waardoor 2 rittens het helaas niet gered hebben. Gelukkig hebben we een surrogaat moeder gevonden die samen met Muis een deel van Witjes rittens heeft opgevangen."

const nest2 = importAll(require.context('./L3/', false, /\.(png|jpe?g|svg)$/));
nest2.posts = importAll(require.context('./L3/posts/', true, /\.(png|jpe?g|svg)$/));
nest2.text = "Muis en Vincent zijn in de nacht van 4-4-2024 en 5-4-2024 op date geweest. Muis op 26-4-2024 vroeg in de avond bevallen van 11 rittens. Ondanks de vroege geboorte deed Muis het super goed met haar rittens. Ze heeft dan ook de zorg voor een paar van Witjes rittens op zich genomen."

export const pastNests = [
  {"L2 - Witje x Loki": nest1},
  {"L3 - Muis x Vincent": nest2},
]
