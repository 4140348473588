import React, { useEffect, useState} from "react";
import propTypes from "prop-types";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";


export function NestNav ({ nests }) {
    const [pairs, setPairs] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);

    useEffect(() => {
        async function makeNav() {
            if (Object.keys(nests[0]).length === 0) {
                setLoading(false);
                return
            }
            else{
                for (const nest of nests) {
                    const link = Object.keys(nest)[0].split(" - ")[0];
                    const title = Object.keys(nest)[0];
                    const value = await Object.values(nest)[0];
                    const pair = {title: title, link: link, img1: value[0], img2: value[1]}
                    setPairs(pairs => [...pairs, pair]);
                }
                setLoading(false);
            }
        }
        makeNav();
    }, []);

    if (loading) {
        return (
            <div className="nest-nav">
                Loading...
            </div>
        )
    }

    return (
        <div className="nest-nav">
            {pairs.map((pair, index) => (
                <RatCard title={pair.title} link={pair.link} img1={pair.img1} img2={pair.img2} key={index} />
            ))}
        </div>
    )
}

NestNav.propTypes = {
    nests: propTypes.array,
}

function RatCard ({ title, link, img1, img2 }) {
    return (
        <Card className="rat-pairing">
            <Link to={link}>
                <h2>{title}</h2>
            </Link>
            <div className="rat-pairing-photo-container">
            <Link to={link}>
                <div className="rat-pair-photo">
                    <img src={img1} />
                </div>
                <div className="rat-pair-photo">
                    <img src={img2} />
                </div>
            </Link>
            </div>
        </Card>
    )
}

RatCard.propTypes = {
    title: propTypes.string,
    link: propTypes.string,
    img1: propTypes.string,
    img2: propTypes.string,
    text: propTypes.string,
}

function Post ({src}) {
    return (
        <div className="rat-post-photo">
            <img src={src} />
        </div>
    )
}

Post.propTypes = {
    src: propTypes.string,
}

// Posts should load all posts in the folder {pair}/posts
function Posts ({pair}) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function makePosts() {
            if (pair.posts && pair.posts.length !== 0) {
                for (const post of pair.posts) {
                    const value = await post;
                    setPosts(posts => [...posts, value]);
                }
                setLoading(false);
            }
            else{
                setLoading(false);
                return
            }
        }
        makePosts();
    }, []);

    if (loading) {
        return (
            <div className="rat-posts">
                Loading...
            </div>
        )
    }
    return (
        <div className="rat-posts">
            {posts.map((post, index) => (
                <Post src={post} key={index} />
            ))}
        </div>
    )
}

Posts.propTypes = {
    pair: propTypes.object,
}

function Page ({pair}) {
    return (
        <div className="container">
            <div className="content">
                <div className="rat-posts-container">
                    <h2>{pair.title}</h2>
                    <p>{pair.text}</p>
                    <div className="rat-posts-photo-container">
                        <div className="rat-pair-photo">
                            <img src={pair.img1} />
                        </div>
                        <div className="rat-pair-photo">
                            <img src={pair.img2} />
                        </div>
                    </div>
                </div>
                <Posts pair={pair} />
            </div>
        </div>
    )
}

Page.propTypes = {
    pair: propTypes.object,
}

export const PairPage = Page;