import React from "react";
import { Button } from "@mui/material";
import { gtag } from "..";
import TagManager from 'react-gtm-module';

// Adding Google Tag Manager
const tagManagerArgs = {
    gtmId: 'GTM-KJHWDP94'
}

function declineCookies() {
    gtag('consent', 'update', {
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'ad_storage': 'denied',
    });
    document.getElementById('cookie-consent').style.display = 'none';
}

function acceptCookies() {
    document.cookie = 'cookie-consent=true; max-age=315360000;';
    gtag('consent', 'update', {
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'ad_storage': 'granted',
    });
    document.getElementById('cookie-consent').style.display = 'none';
    TagManager.initialize(tagManagerArgs);
}

export default function ConsentBanner() {
    const cookieConsent = document.cookie.split('; ').find(row => row.startsWith('cookie-consent'))?.split('=')[1];
    if (cookieConsent === 'true') {
        gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
        });
        TagManager.initialize(tagManagerArgs);
        return false;
    }
    return (
        <>
            <div id="cookie-consent">
                <p>This website uses cookies to ensure you get the best experience on our website.</p>
                <Button id="decline" onClick={declineCookies}>Decline</Button>
                <Button id="accept" onClick={acceptCookies}>Accept</Button>
            </div>
        </>
    )
}