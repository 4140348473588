import React, {useEffect, useState} from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { Route, Routes, BrowserRouter, Link} from "react-router-dom";
import './index.css';
import './App.css';
import Home from './pages/home.jsx';
import Info from './pages/info.jsx';
import Care from './pages/care.jsx';
import Males from './pages/males.jsx';
import Females from './pages/females.jsx';
import Memoriam from './pages/mem.jsx';
import Contact from './pages/contact.jsx';
import Layout from './components/layout.jsx';
import Upcoming from './pages/upcoming.jsx';
import { PairPage } from './components/rat_pairing.jsx';
import { pastNests } from './pages/resrc/nests/past/folders';
import { upcomingNests } from './pages/resrc/nests/upcoming/folders';
import Past from './pages/past.jsx';
import Reserve from './pages/reserve.jsx';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: { // '#6b1701'
          main: 'rgba(107, 23, 1)',
        },
        secondary: {
          main: 'rgb(139, 5, 50)',
        },
        background: {
          default: '#rgb(41, 16, 9)',
          paper: 'rgba(19, 4, 1, 0.4)',
        },
        text: {
          primary: '#d1d1d1',
          disabled: 'rgba(255,255,255,0.6)',
          secondary: 'rgba(255,255,255,0.5)',
        },
        divider: 'rgba(0,0,0,0.12)',
      },
});

// Add consent for cookies
window.dataLayer = window.dataLayer || [];

export function gtag(args) { dataLayer.push(args); }

gtag('consent', 'default', {
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'ad_storage': 'denied',
  'analytics_storage': 'denied',
  'wait_for_update': 500,
});

dataLayer.push({'gtm.start': new Date().getTime(), 'event': 'gtm.js'});


export default function App(){
    const [pastPairs, setPastPairs] = useState([]);
    const [upcomingPairs, setUpcomingPairs] = useState([]);

    useEffect(() => {
        async function makeNav(nests, setPairs) {
          if (Object.keys(nests[0]).length === 0) {
            return
          } else{
            for (const nest of nests) {
              const title = Object.keys(nest)[0];
              const link = title.split(" - ")[0];
              const value = await Object.values(nest)[0];
              const posts = await Object.values(nest)[0].posts;
              const text = await Object.values(nest)[0].text;
              const pair = {title: title, link: link, img1: value[0], img2: value[1], text: text, posts: posts}
              setPairs(pairs => [...pairs, pair]);
            }
          }
        }
      makeNav(pastNests, setPastPairs);
      makeNav(upcomingNests, setUpcomingPairs);
    }, []);

    return(
        <ThemeProvider theme={theme}>
          <div className='isolate'>
            <div className='noise'>
              {/* <svg viewBox='0 0 800 800' xmlns='http://www.w3.org/2000/svg'>
                <filter id='noiseFilter'>
                  <feTurbulence 
                    type='fractalNoise' 
                    baseFrequency='2.09' 
                    numOctaves='6' 
                    stitchTiles='stitch'/>
                </filter>
                
                <rect width='100%' height='100%' filter='url(#noiseFilter)'/>
              </svg> */}
            </div>
            <div className='overlay'></div>
          </div>
          <BrowserRouter basename='/'>
              <Routes>
                  <Route path="/" element={<Layout />}>
                      <Route index element={<Home />} />
                      <Route path="over-ons" element={<Info />} />
                      <Route path="verzorging" element={<Care />} />
                      <Route path="mannen" element={<Males />} />
                      <Route path="vrouwen" element={<Females />} />
                      <Route path="in-memoriam" element={<Memoriam />} />
                      <Route path="contact" element={<Contact />} />
                      <Route path="huidige" element={<Upcoming />} />
                          {upcomingPairs.map((pair) => (
                            <Route key={pair.link} path={`huidige/${pair.link}`} element={<PairPage pair={pair} />} />
                          ))}
                      <Route path="verleden" element={<Past />} />
                          {pastPairs.map((pair) => (
                            <Route key={pair.link} path={`verleden/${pair.link}`} element={<PairPage pair={pair} />} />
                          ))}
                      <Route path="reserveren" element={<Reserve />} />
                  </Route>
              </Routes>
          </BrowserRouter>
        </ThemeProvider>
)}

const rootElement = document.getElementById('root');
let root;

if (rootElement.hasChildNodes()) {
  root = hydrateRoot(
    rootElement, <App />
  );
} else {
  root = createRoot(rootElement);
}

root.render(<App />);